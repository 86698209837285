import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Img from "gatsby-image";
import { Link } from "gatsby"
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { AnimationWrapper } from "react-hover-animation";

import Header from "../components/header.jsx";
import Footer from "../components/footer.jsx";
import Seo from "../components/seo";

const {MARGIN_TOP} = require("../utils/constants");

const iconStyle = { color: "#959595" };

const About = ({ data }) => {
  const mgPhoto = data.contentfulPerson.myImage;
  const tripsImage = data.contentfulPerson.trips;
  const equipments = data.contentfulPerson.equipments; // [] of equipments
  const description = JSON.parse(data.contentfulPerson.description.raw);
  const press = JSON.parse(data.contentfulPerson.press.raw);
  return (
    <React.Fragment>
      <Seo
        title="About me | mg98photographer"
        url="https://www.mg98photographer.it/about"
        description="Sono Marco, un fotografo con sede a Treviso. Il mio obiettivo? Dare ai miei clienti delle immagini uniche e pieni di emozioni."
        keywords={[
          "marco garbi",
          "mg98photographer",
          "mg98",
          "fotografo",
          "fotografo treviso",
          "fotostampa",
          "ph",
          "ph treviso",
          "about me",
        ]}
      >
        {/*FontAwesome  icons*/}
        <link
          href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
          rel="stylesheet"
        />
      </Seo>
      <Header />
      <Container style={{ marginTop: `${MARGIN_TOP}px` }} fluid>
        <div className="mb-3">
          <Link style={{ color: "black" }} to="/"><i className="fas fa-home" /> Home</Link>
        </div>
        <Row>
          <Col xs={12} md={6}>
            <Img
              className="rounded"
              fluid={mgPhoto.fluid}
              key={mgPhoto.id}
              alt={mgPhoto.description || mgPhoto.title}
            />
          </Col>
          <Col xs={12} md={6}>
            <h2 className="mt-4 mt-md-0">ABOUT ME</h2>
            {documentToReactComponents(description)}
            <a
              className="ml-2"
              href="https://www.tiktok.com/@mg98photographer?lang=it-IT"
              aria-label="MG on TikTok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AnimationWrapper
                style={{ display: "inline" }}
                config={{
                  color: {
                    initial: iconStyle.color,
                    onHover: "black",
                  },
                }}
              >
                TikTok{' '}
                <i className="fab fa-tiktok"></i>
              </AnimationWrapper>
            </a>
            <a
              className="ml-2"
              href="https://www.instagram.com/mg98photographer/"
              aria-label="MG on Instagram"
              target="_blank"
              rel="noopener noreferrer"
              style={iconStyle}
            >
              <AnimationWrapper
                style={{ display: "inline" }}
                config={{ color: { initial: iconStyle.color, onHover: "#d6249f" } }}
              >
                Instagram{' '}
                <i className="fab fa-instagram"></i>
              </AnimationWrapper>
            </a>
            <a
              className="ml-2"
              href="https://www.youtube.com/channel/UCPiBWedP3g0ayuFbpIZpDMw"
              aria-label="MG on Youtube"
              target="_blank"
              rel="noopener noreferrer"
              style={iconStyle}
            >
              <AnimationWrapper
                style={{ display: "inline" }}
                config={{
                  color: {
                    initial: iconStyle.color,
                    onHover: "red",
                  },
                }}
              >
                YouTube{' '}
                <i className="fab fa-youtube"></i>
              </AnimationWrapper>
            </a>
            <hr className="mt-4 mb-4" />
            <h4> PRESS</h4>
            {documentToReactComponents(press)}
          </Col>
        </Row>
        <Row
          className="mt-4 mb-4 pb-4 justify-content-center"
          style={{ backgroundColor: "#f7f2ee" }}
        >
          <Col xs={12} className="mb-4">
            <h2 className="d-flex mt-4 justify-content-center">
              MY EQUIPMENTS
            </h2>
          </Col>
          {equipments.map((item) => (
            <Col className="mb-4 mb-md-0" xs={12} md={2} key={item.image.id}>
              <AnimationWrapper>
                <a
                  href={item.url.url}
                  className="d-flex justify-content-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fixed={item.image.fixed}
                    key={item.image.id}
                    alt={item.image.title || item.name}
                  />
                </a>
              </AnimationWrapper>
            </Col>
          ))}
        </Row>
        <h2 className="d-flex mt-4 mb-4 justify-content-center">MY TRIPS</h2>
        <div style={{ overflowX: "scroll" }}>
          <Img
            fixed={tripsImage.fixed}
            key={tripsImage.id}
            alt={tripsImage.description || tripsImage.title}
          />
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export const query = graphql`
  {
    contentfulPerson {
      id
      press {
        raw
      }
      description {
        raw
      }
      equipments {
        id
        image {
          title
          fixed(height: 160, width: 160, quality: 100) {
            ...GatsbyContentfulFixed
          }
          id
        }
        name
        url {
          url
        }
      }
      myImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
        id
        title
      }
      trips {
        fixed(quality: 100, resizingBehavior: PAD, height: 400, width: 1920) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`;

export default About;
